import { Link } from "react-router-dom";

function InfoLegal () {
    return (<div style={{ fontFamily: 'roboto', maxWidth: "calc(100% - 40px)", width: 1000, padding: 20, margin: "auto" }}>
        <h2>INFORMATIONS LÉGALES</h2>

        <h3>Propriété et responsabilité éditoriale</h3>

        <p>Le présent site est la propriété de SOCOBOMA<br/>
        Société coopérative d’achats en commun des bouchers et bouchers-charcutiers du département de la Manche<br/>
        Société anonyme à capital variable<br/>
        Inscrite au RCS de Coutances sous le numéro 315 475 921 00013<br/>
        Dont le numéro de TVA intracommunautaire est : FR 50 502 006 CE<br/>
        Ayant son siège social à :<br/>
        La Raoulerie – 50008 Saint-Lô – France<br/>
        Tél : 02 33 57 74 25<br/>
        Directeur de publication : Edgard Lemercier</p>
        <h3>Hébergement :</h3>
        <p>L'hébergement de www.socoboma.fr est assuré par la société DATAOUEST.<br/>
        Plus d'informations sur dataouest.fr.</p>
        <h3>Propriété intellectuelle</h3>
        <p>Le site Internet, sa structure générale, ainsi que les textes, images animées ou non, savoir-faire, dessins, graphismes (...) et tout autre élément composant le site, sont la propriété de YAKA COMMUNICATION. Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet est interdite et constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété intellectuelle.
        Les marques de l’exploitant du site Internet, ainsi que les logos figurant sur le site sont des marques déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos, effectuée à partir des éléments du site sans l’autorisation expresse de l’exploitant du site Internet ou de son ayant-droit est donc prohibée, au sens de l’article L713-2 du CPI.
        SOCOBOMA ne collecte pas de données personnelles</p>
        <div style={{ textAlign: "center" }}>
            <Link style={{ textDecoration: "none", width: "100%" }} to="/">
                <img src="/img/commun/cross.png" style={{ width: 40 }}/>
            </Link>
        </div>
    </div>);
}

export default InfoLegal;