import "./footer.css";
import { HashLink as Link } from 'react-router-hash-link';

function Footer () {
    return (<footer>
        <div>
            <p style={{ fontSize: 12 }}>© Y’AKA communication</p>
        </div>
        <div>
            <Link style={{ textDecoration: "none" }} to="/infoLegal">
                <button style={{ fontSize: 12 }}>Informations légales</button>
            </Link>
        </div>
        <div>
            <Link style={{ textDecoration: "none" }} to="/CGV">
                <button style={{ fontSize: 12 }}>CGV</button>
            </Link>
        </div>
    </footer>);
}

export default Footer;