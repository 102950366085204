import { Page, Image, Text, View, Document, StyleSheet  } from '@react-pdf/renderer';

const MyDoc = ({ promo }) => {
    const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    let dateStart = "";
    let dateEnd = "";
    let dateStartObj = new Date(promo.dateStart);
    let dateEndObj = new Date(promo.dateEnd);

    dateStart += dateStartObj.getDate() + " " + monthNames[dateStartObj.getMonth()];
    dateEnd += dateEndObj.getDate() + " " + monthNames[dateEndObj.getMonth()] + " " + dateEndObj.getFullYear();

    return (<Document>
            <Page size="A4" style={{ padding: "20px 20px", paddingBottom: "50px" }}>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Image style={{ width: 200 }} src="/img/header/Logo_Big.png"/>
                    <Image style={{ width: 80 }} src="/img/header/Logo_date.png"/>
                </View>
                <View style={{ textAlign: "center", padding: "20px 0px" }}>
                    <Text style={{  fontSize: 20, color: "#145588" }}>{ promo.name }</Text>
                    <Text style={{  fontSize: 18, textTransform: "capitalize" }}>DU { dateStart.toUpperCase() } AU { dateEnd.toUpperCase() }</Text>
                </View>
                {
                   (promo.img !== undefined) ? <View style={{ textAlign: "center", justifyContent: "center", alignItems: "center", marginBottom: 30 }}>
                    <Image  style={{ maxWidth: 300, maxHeight: 300 }} src={ process.env.REACT_APP_API_URL + "/img/" + promo.img }/>
                    </View> : false
                }
                {
                    promo.aricles.map((val, i) => {
                        let type;

                        switch (val.type) {
                            case "KILO":
                                type = "kg";
                                break;
                            case "PIECE":
                                type = "Piéce";
                                break;
                            case "BARQUETTE":
                                type = "Barquette";
                                break;
                            case "CARTON":
                                type = "Carton";
                                break;
                            case "BOITE":
                                type = "Boîte";
                                break;
                            case "SAC":
                                type = "Sac";
                                break;
                            default:
                                type = "ERROR";
                        }
                        return (<View key={ i } style={{ padding: "10px 0px", marginTop: "-10px" }} wrap={ false }>
                            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                                <Text style={{ width: 70, fontSize: 11, textAlign: "left" }}>{ val.reference }</Text>
                                <Text style={{ width: 350, fontSize: 14, fontWeight: "extrabold" }}>{ val.name } { val.conditionnement }</Text>
                                <Text style={{ width: 50, fontSize: 11, textAlign: "right", textDecoration: "line-through" }}>{ val.priceOld } €</Text>
                                <Text style={{ width: 50, fontSize: 11, textAlign: "right", color: "rgb(20, 85, 136)" }}>{ val.priceNew } € </Text>
                                <Text style={{ width: 70, fontSize: 11, paddingLeft: "5px" }}> / { type }</Text>
                            </View>
                            <Text style={{ marginLeft: 65, marginRight: 140, fontSize: 12, marginTop: 0 }}>{ val.descriptif }</Text>
                        </View>)
                    })
                }
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 100, bottom: 20, position: 'absolute', left: 40, right: 40 }} fixed>
                    <View style={{ alignItems: "center", paddingTop: "30px" }}>
                        <Text style={{ fontSize: 8 }}>SOCOBOMA - SA à capital variable - La Raoulerie – 50008 Saint-Lô Cedex – <Text style={{ fontSize: 10 }}>Tél. 02 33 57 74 25</Text> - SIRET 31547592100013</Text>
                        <Text style={{ fontSize: 8 }}> RCS Coutances – code NAF 4617A - N° Agrément communautaire FR 50.502.006 CE</Text>
                    </View>
                </View>
                </Page>
        </Document>)
};

export default MyDoc;