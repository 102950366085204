import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageTemplate from "./components/pageTemplate"
import Contact from './pages/contact/contact';
import PromoHub from './pages/promotion/promotionInside/promotionHub';
import CVG from './pages/utils/cvg';
import InfoLegal from './pages/utils/infoLegal';

const Accueil = React.lazy(() => import("./pages/accueil/accueil"));
const Cuisines = React.lazy(() => import('./pages/cuisines/cuisines'));
const Charcuterie = React.lazy(() => import('./pages/charcuterie/charcuterie'));
const Epice = React.lazy(() => import('./pages/epice/epice'));
const Produits = React.lazy(() => import('./pages/produits/produits'));
const Surgeles = React.lazy(() => import('./pages/surgeles/surgeles'));
const Volaille = React.lazy(() => import('./pages/volaille/volaille'));
const AdminRooter = React.lazy(() => import('./pages/admin/router'));
const PromotionStart = React.lazy(() => import('./pages/promotion/promotionStart/promotionStart'))

ReactDOM.render(
  	<React.StrictMode>
		<BrowserRouter>
			<Switch>
				<Route exact path='/admin'>
					<React.Suspense fallback={<div>Chargement...</div>}>
						<section>
							<div>
								<AdminRooter/>								
							</div>
						</section>
					</React.Suspense>
				</Route>
				<PageTemplate selectedTab={ 0 } path='/charcuterie'>
					<Charcuterie/>
				</PageTemplate>
				<PageTemplate selectedTab={ 1 } path='/cuisines'>
					<Cuisines/>
				</PageTemplate>
				<PageTemplate selectedTab={ 2 } path='/volaille'>
					<Volaille/>
				</PageTemplate>
				<PageTemplate selectedTab={ 5 } path='/produits'>
					<Produits/>
				</PageTemplate>
				<PageTemplate selectedTab={ 4 } path='/epice'>
					<Epice/>
				</PageTemplate>
				<PageTemplate selectedTab={ 3 } path='/surgeles'>
					<Surgeles/>
				</PageTemplate>
				<PageTemplate path='/promo'>
					<PromotionStart/>
				</PageTemplate>
				<PageTemplate path='/promotion'>
					<PromoHub/>
				</PageTemplate>
				<PageTemplate path='/contact'>
					<Contact/>
				</PageTemplate>
				<PageTemplate path='/CGV'>
					<CVG/>
				</PageTemplate>
				<PageTemplate path='/infoLegal'>
					<InfoLegal/>
				</PageTemplate>
				<PageTemplate path='*'>
					<Accueil/>
				</PageTemplate>
				<Route>
					<h2>404 route not found</h2>
				</Route>
			</Switch>
		</BrowserRouter>
  	</React.StrictMode>,
  	document.getElementById('root')
);

// TODO
/*
	Cochon piece et kilo
*/



//Logo jaune   X

// Footer a revoire.   X

//Fermer cgv et mension legal