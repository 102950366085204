import React from "react";
import { Route, useHistory } from "react-router-dom";
import Header from "./header/header";
import "./commun.css"
import { Component, Suspense } from "react/cjs/react.production.min";
import Footer from "./footer/footer";


class PageSousTemplate extends Component {
    constructor () {
        super();
        this.state = {
            open: true
        }
    }

    render () {
        return (<Route exact path={ this.props.path }>
            <Header selectedTab={ this.props.selectedTab } className="hidePC" navBarOpened={ (val) => this.setState({ open: val }) } history={ this.props.history }/>
                <div style={{ minHeight: "calc(100vh - 200px)" }}>
                    {
                        (!this.state.open) ? <div className="hidePC" style={{ height: 140 }}></div> : false
                    }
                    {
                        this.props.children
                    }
                </div>
            <Footer/>
        </Route>);
    }
}

export default function PageTemplate (props) {
    const history = useHistory();

    return (<PageSousTemplate selectedTab={ props.selectedTab } history={ history } path={ props.path }>
        <Suspense fallback={<div>Chargement...</div>}>
            <section>
                { props.children }
            </section>
        </Suspense>
    </PageSousTemplate>);
}
