import promotionSave from "../promotionSave";
import ProductInside from "./productInside";
import PromotionInside from "./promotionInside";
import PromotionPDF from "./promotionPDF";

function PromoHub () {
    const queryParams = new URLSearchParams(window.location.search);
    const id = Number(queryParams.get("id"));

    if (id === NaN || id < 0 || !promotionSave.promo || promotionSave.promo.length <= id) {
        document.location.href = "/promo";
        return (false);
    }

    let promo = promotionSave.promo[id];
    return (<div id="promotionStart">
            { (promo.type === "PROMO") ? <PromotionInside/> : false }
            { (promo.type === "PRODUCT") ? <ProductInside promo={ promo }/> : false }
            { (promo.type === "PDF") ? <PromotionPDF promo={ promo }/> : false }
    </div>);
}

export default PromoHub;