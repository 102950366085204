import { Component } from "react/cjs/react.production.min";
import "./contact.css";
import axios from "axios";
import { HashLink as Link } from 'react-router-hash-link';

class Contact extends Component {
    constructor () {
        super ();
        this.state = {
            finished: false,
            name: "",
            surname: "",
            compagny: "",
            mail: "",
            tel: "",
            obj: "",
            content: "",
            

            errorName: false,
            errorEmail: false,
            errorMessage: false
        };
    }

    changeEmail (val)
    {
        if (val.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            this.setState({ mail: val, errorEmail: false })
        else
            this.setState({ mail: val })
    }

    send ()
    {
        let errors = {};

        if (this.state.name === "" || this.state.surname === "")
            errors.errorName = true;
        if (!this.state.mail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            errors.errorEmail = true;
        if (this.state.content === "")
            errors.errorMessage = true;
        if (Object.keys(errors).length !== 0) {
            this.setState(errors);
            return;
        }

        const params = new URLSearchParams([
            [ 'name', this.state.name ], 
            [ 'surname', this.state.surname ], 
            [ 'compagny', this.state.compagny ], 
            [ 'mail', this.state.mail ], 
            [ 'tel', this.state.tel ], 
            [ 'obj', this.state.obj ], 
            [ 'content', this.state.content ]])

        axios.post(process.env.REACT_APP_API_URL + "/email?" + params).then((ret) => {
            console.log("WTF")
            this.setState({ finished: true });
        })
    }

    emailEditor () {
        return (<div>
            <h1 style={{ textAlign: "center", margin: 0, paddingTop: 30 }}>Nous contacter</h1>
            <p>Nom et prénom *</p>
            <input placeholder="Nom" value={ this.state.name } onChange={ (val) => this.setState({ name: val.target.value, errorName: false }) } className="nameInput"/>
            <input placeholder="Prénom" value={ this.state.surname } onChange={ (val) => this.setState({ surname: val.target.value, errorName: false }) }  className="nameInput"/>
            {
                (this.state.errorName) ? <><br/><br/><i>{ "Veuillez renceiner un noms et un prenom." }</i></> : false
            }
            <p>Entreprise</p>
            <input value={ this.state.compagny } onChange={ (val) => this.setState({ compagny: val.target.value }) } />
            <p>Mail *</p>
            <input value={ this.state.mail } onChange={ (val) => this.changeEmail(val.target.value) }/>
            {
                (this.state.errorEmail) ? <><br/><br/><i>{ "Veuillez renceiner une email valide." }</i></> : false
            }
            <p>Téléphone</p>
            <input value={ this.state.tel } onChange={ (val) => this.setState({ tel: val.target.value }) } />
            <p>Objet</p>
            <input value={ this.state.obj } onChange={ (val) => this.setState({ obj: val.target.value }) } />
            <p>Message *</p>
            <textarea value={ this.state.content } onChange={ (val) => this.setState({ content: val.target.value, errorMessage: false }) }/>
            {
                (this.state.errorMessage) ? <><br/><br/><i>{ "Veuillez renceiner un message." }</i><br/></> : false
            }
            <button onClick={ () => this.send() }>Envoyer</button>
        </div>)
    }

    finished () {
        return (<div>
            <h1 style={{ textAlign: "center", margin: 0, paddingTop: 30 }}>Email envoyée!</h1>
            <div style={{ textAlign: "center" }}>
                <img style={{ color: "green", width: "100%", maxWidth: 200, marginTop: 50 }} src="/img/contact/done.svg"/><br/>
                <Link to="/">
                    <button>Retourner a l'acceuil</button>
                </Link>
            </div>
        </div>)
    }

    render () {
        return <div id="contact">
            {
                (this.state.finished) ? this.finished() : this.emailEditor()
            }
        </div>;
    }
}

export default Contact;