import MyDoc from "../pdf/promotionPDF";
import promotionSave from "../promotionSave";
import '../promotionStart/promotionStart.css';
import {  PDFDownloadLink  } from '@react-pdf/renderer';

function Article (val) {
    let type;

    switch (val.type) {
        case "KILO":
            type = "kg";
            break;
        case "PIECE":
            type = "Piéce";
            break;
        case "BARQUETTE":
            type = "Barquette";
            break;
        case "CARTON":
            type = "Carton";
            break;
        case "BOITE":
            type = "Boîte";
            break;
        case "SAC":
            type = "Sac";
            break;
        default:
            type = "ERROR";
    }



    return (<div className="promotionInsideArticles">
        <p className="promotionInsideArticlesNameMobile">{ val.name } { val.conditionnement }</p>
        <table>
            <tbody>
                <tr>
                    <td className="promotionInsideArticlesRef">{ val.reference }</td>
                    <td className="promotionInsideArticlesName">{ val.name } { val.conditionnement }</td>
                    <td className="promotionInsidePriceOld">{ val.priceOld } €</td>
                    <td className="promotionInsidePriceNew">{ val.priceNew } €</td>
                    <td className="promotionInsidePriceType"> / { type }</td>
                </tr>
            </tbody>
        </table>
        <pre style={{ whiteSpace: "pre-wrap" }} className="promotionInsidePriceDescriptif">{ val.descriptif }</pre>
    </div>);
}

function PromotionInside () {
    const queryParams = new URLSearchParams(window.location.search);
    const id = Number(queryParams.get("id"));

    console.log(id);
    if (id === NaN || id < 0 || !promotionSave.promo || promotionSave.promo.length <= id) {
        document.location.href = "/socoboma/promo";
        return (false);
    }
    let promo = promotionSave.promo[id];
    const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    let dateStart = "";
    let dateEnd = "";
    let dateStartObj = new Date(promo.dateStart);
    let dateEndObj = new Date(promo.dateEnd);

    dateStart += dateStartObj.getDate() + " " + monthNames[dateStartObj.getMonth()];
    dateEnd += dateEndObj.getDate() + " " + monthNames[dateEndObj.getMonth()] + " " + dateEndObj.getFullYear();

    console.log(promo.img);
    return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "stretch" }}>
        <div style={{ flexGrow: 1, backgroundImage: "url(" + process.env.REACT_APP_API_URL + "/img/" + promo.img + ")", backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
            {// (promo.img !== undefined) ? <img src={ process.env.REACT_APP_API_URL + "/img/" + promo.img } style={{ width: "100%" }}/> : false
            }
        </div>
        <div id="promotionInside" style={{ margin: 0 }}>
            <div style={{ textAlign: "center", margin: "50px 0" }}>
                <img style={{ width: 300 }} src="/img/promo/promoBanderol.png"/>
            </div>
            <div>
                <div id="promotionInsidePDF">
                    <PDFDownloadLink document={ <MyDoc promo={ promo }/> } fileName={ promo.name + '.pdf' }>
                        <img style={{ width: 60 }} src="/img/promo/promo.png"/>
                    </PDFDownloadLink>
                </div>
                <h1>{ promo.name }</h1>
                <h3>DU { dateStart } AU { dateEnd }</h3>
                {
                    promo.aricles.map((val, i) => {
                        return (Article(val))
                    })
                }
            </div>
            <p style={{ fontFamily: "roboto", textAlign: "center" }}><i>Tarifs HT</i></p>
        </div>
        <div  style={{ flexGrow: 1 }}/>
    </div>);

}

export default PromotionInside;