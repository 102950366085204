import { PDFDownloadLink } from "@react-pdf/renderer";
import { Component } from "react";
import MyDoc from "../pdf/productPDF";

function Conditionnement ({ val, type }) {
    return (
        <div className="promotionInsideArticles">
        <p className="promotionInsideArticlesNameMobile">{ val.name } { val.conditionnement }</p>
        <table style={{ width: "50%" }}>
            <tbody>
                <tr>
                    <td className="promotionInsideArticlesName" style={{ textAlign: "right" }}>{ val.name } { val.conditionnement }</td>
                    <td className="promotionInsidePriceNew">{ val.price } € </td>
                    <td className="promotionInsidePriceType"> / { type }</td>
                </tr>
            </tbody>
        </table>
    </div>)
}

function ProductInside ({ promo }) {
    const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    let dateStart = "";
    let dateEnd = "";
    let dateStartObj = new Date(promo.dateStart);
    let dateEndObj = new Date(promo.dateEnd);

    dateStart += dateStartObj.getDate() + " " + monthNames[dateStartObj.getMonth()];
    dateEnd += dateEndObj.getDate() + " " + monthNames[dateEndObj.getMonth()] + " " + dateEndObj.getFullYear();

    let type;

    switch (promo.unite) {
        case "KILO":
            type = "kg";
            break;
        case "PIECE":
            type = "Piéce";
            break;
        case "BARQUETTE":
            type = "Barquette";
            break;
        case "CARTON":
            type = "Carton";
            break;
        case "BOITE":
            type = "Boîte";
            break;
        case "SAC":
            type = "Sac";
            break;
        default:
            type = "ERROR";
    }

    return (<div id="promotionInside">
        <div style={{ textAlign: "center", margin: "50px 0" }}>
            <img style={{ width: 300 }} src="/img/promo/promoBanderol.png"/>
        </div>
        <div style={{ textAlign: "center" }}>
            <div id="promotionInsidePDF">
                <PDFDownloadLink document={ <MyDoc promo={ promo }/> } fileName={ promo.name + '.pdf' }>
                    <img style={{ width: 60 }} src="/img/promo/promo.png"/>
                </PDFDownloadLink>
            </div>
            <h1>{ promo.name }</h1>
            <h3>DU { dateStart } AU { dateEnd }</h3><br/>
            <img style={{ maxWidth: "100%", maxHeight: 500 }} src={ process.env.REACT_APP_API_URL + "/img/" + promo.img }/><br/><br/>
            <div className="promotionInsideArticles">
                <p className="promotionInsideArticlesNameMobile">{ promo.name } { promo.conditionnement }</p>
                <table>
                    <tbody>
                        <tr>
                            <td className="promotionInsideArticlesRef">{ promo.ref }</td>
                            <td className="promotionInsideArticlesName" style={{ textAlign: "left", fontSize: 24 }}>{ promo.name } </td>
                        </tr>
                    </tbody>
                </table>
                <p className="promotionInsidePriceDescriptif" style={{ textAlign: "left" }}>{ promo.description }</p>
            </div><br/><br/><br/>
            {
                promo.prices.map((val, i) => {
                    return (<Conditionnement val={ val } type={ type } key={ i }/>);
                })
            }<br/><br/><br/>
        </div>
    </div>);
}


export default ProductInside;