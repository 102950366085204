import { Component, useState } from "react"
import './header.css'
import './hamburger.css'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { animateScroll as scroll } from "react-scroll";
import { HashLink as Link } from 'react-router-hash-link';
import "../commun.css"

/*
**=====================> Big header  "#CAC7BA"
*/

class HeaderTd extends Component {
    render () {
        return (<td>
            <h2 style={{ "color": (this.props.open || this.props.selectedTab) ? "rgb(34, 32, 33)" : false }} className="headerNavTitle" onClick={ this.props.onClick }>{ this.props.children }</h2>
            {
                (this.props.open) ? <div>
                    <div>
                        <br/>
                        {
                            this.props.content.map((content, index) => {
                                return (<div className="BigHeaderNavbarSmallTitle" key={ index }>{ content } </div>);
                            })
                        }
                    </div>
                </div> : false
            }
        </td>);
    }
}

class HeaderBigNavBar extends Component {
    render () {
        
        return (
            <table id="bigNavBar">
                <tbody>
                    <tr>
                        {
                            this.props.menu.map((element, index) => {
                                return (<HeaderTd
                                    selectedTab={ this.props.selectedTab === index }
                                    key={ index }
                                    content={ element.content }
                                    open={ index === this.props.selected } onClick={ () => this.props.changeSelected(index) }>
                                        { element.title }
                                    </HeaderTd>)
                            })
                        }
                    </tr>
                </tbody>
            </table>);
    }
};

/*
**=====================> Small nav bar
*/

class HeaderSmallNavBarContent extends Component {
    constructor () {
        super ();
        this.state = {
            open: false
        };
    }

    render () {
        return (<div>
            <div className="smallNavBarTitle" onClick={ () => this.setState({ open: !this.state.open }) }>
                <h2 >{ this.props.title }</h2>
                <h2>{ (this.state.open) ?  "-" :  "+" }</h2>
            </div>
            {
                (this.state.open) ? this.props.content.map((element, index) => {
                    return (<div key={ index }>{ element }</div>)
                }) : false
            }
        </div>)
    }
}

class HeaderSmallNavBar extends Component {
    render () {
        return (<nav id="smallNavBar">
            {
                this.props.menu.map((element, index) =>{
                    return (<HeaderSmallNavBarContent key={ index } title={ element.title } content={ element.content }/>)
                })
            }
        </nav>);
    }
};

/*
**=====================> Header code
*/

const Header = (props) =>  {
    const [smallNavBarVisible, setSmallNavBarVisible] = useState(false);
    const [bigNavBarSelected, setBigNavBarSelected] = useState(-1);

    const changeSmallNavBarVisible = () => {
        setSmallNavBarVisible(!smallNavBarVisible);
        if (!smallNavBarVisible)
            document.body.classList.add("boddyOverflowOn");
        else
            document.body.classList.remove("boddyOverflowOn");
        props.navBarOpened(smallNavBarVisible);
    }

    const changeNabBarSelected = (value) => {
        setBigNavBarSelected((value === bigNavBarSelected) ? -1 : value);
    }

    const closeBigNavBar = () => {
        setBigNavBarSelected(-1)
        setSmallNavBarVisible(false);
        document.body.classList.remove("boddyOverflowOn");
        props.navBarOpened(true);
    }

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 800
        });
    }

    const menu = [
        {
            title: "Charcuterie",
            content: [
                <Link onClick={ closeBigNavBar } to='/charcuterie#jambonPage'>Jambons <br/> crus, fumés, cuits</Link>, 
                <Link onClick={ closeBigNavBar } to='/charcuterie#Saucissons'>Saucissons<br/>Chorizo / Salami<br/>Mortadelle / Cervelas<br/>Andouille</Link>,
                <Link onClick={ closeBigNavBar } to='/charcuterie#pates'>Pâtés<br/>Pâtés en croûte<br/>Galantine<br/>Tête roulée</Link>, 
                <Link onClick={ closeBigNavBar } to='/charcuterie#rillettes'>Rillettes<br/>Terrines de la mer</Link>, 
                <Link onClick={ closeBigNavBar } to='/charcuterie#saucisse'>Andouillettes<br/>Saucisses<br/>Boudin / Tripes</Link>,
                <Link onClick={ closeBigNavBar } to='/charcuterie#lardon'>Lardons / Bacon<br/>Poitrine<br/>Jambonneau<br/>Rôti / Palette...</Link>, 
                <Link onClick={ closeBigNavBar } to='/charcuterie#patiserie'>Charcuteries pâtissières</Link>,
                <Link onClick={ closeBigNavBar } to='/charcuterie#saisonier'>Les Saisonniers<br/>(raclette, choucroute…)</Link>]
        },
        {
            title: "Les cuisinés",
            content: [
                <Link onClick={ closeBigNavBar } to='/cuisines#salade-terre'>Salades «côté terre»<br/>Taboulés<br/>Légumes / Antipasti</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#salade-mer'>Salades «côté mer»<br/>Rillettes de la mer</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#saumon'>Saumon / Truite<br/>Anchois / Haddock<br/> Hareng / Roulés…</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#plats-terre'>Plats cuisinés «côté terre»</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#plats-mer'>Plats cuisinés «côté mer»<br/>Cassolettes / Coquilles</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#saisonniersCuisines'>Accompagnements</Link>,
                <Link onClick={ closeBigNavBar } to='/cuisines#saisonniersCuisines'>Les Saisonniers<br/>Nouvel an chinois<br/>Gamme festive</Link>]
        },
        {
            title: "Volaille",
            content: [<Link onClick={ closeBigNavBar } to='/volaille#oeuf'>Œufs / Poulet / Pintade</Link>,
                <Link onClick={ closeBigNavBar } to='/volaille#canard'>Canard / Canette</Link>,
                <Link onClick={ closeBigNavBar } to='/volaille#dinde'>Dinde / Poule / Coq<br/>Coquelet / Caille<br/>Pigeonneau / Lapin </Link>,
                <Link onClick={ closeBigNavBar } to='/volaille#elabore'>Élaborés cuits et crus<br/>Conserves</Link>,
                <Link onClick={ closeBigNavBar } to='/volaille#festive'>Gamme festive<br/>Volaille / Gibier / Foie gras</Link>]
        },
        {
            title: "Surgelés",
            content: [<Link onClick={ closeBigNavBar } to='/surgeles'>Nos produits surgelés</Link>]
        },
        {
            title: "Épicerie",
            content: [<Link onClick={ closeBigNavBar } to='/epice#bocaux'>PRODUITS FINIS</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#bocaux'>Bocaux Qualichoix<br/>Légumes, champignons...<br/>Sauces tomates<br/>Chips / Pâtes<br/>Soupes / Confitures</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#vins'>Condiments : moutarde, mayonnaise, sauces...<br/>Cornichons / Olives<br/>Vins, champagnes, bières</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#conserve'>PRODUITS DE FABRICATION</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#conserve'> Conserves 3/1, 4/4 et 5/1</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#accompagnements'>Accompagnements</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#accompagnements'>Assaisonnement</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#sauces'>Sauces, fonds, jus, fumets</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#barbecue'>Alcools dénaturés</Link>,
            <Link onClick={ closeBigNavBar } to='/epice#barbecue'>Produits saisonniers Barbecue</Link>,]
        },
        {
            title: "Multi-produits",
            content: [<Link onClick={ closeBigNavBar } to='/produits#conditionnement'>Conditionnements</Link>,
                <Link onClick={ closeBigNavBar } to='/produits#entretien'>Produits d'entretien<br/> & hygiène</Link>,
                <Link onClick={ closeBigNavBar } to='/produits#materiel'>Petit matériel</Link>]
        }
    ];

    let className = "hamburger hamburger--spring";
    if (smallNavBarVisible)
        className += " is-active";

    const ref = useDetectClickOutside({ onTriggered: closeBigNavBar });
    return (<div ref={ ref }><header className={ (smallNavBarVisible) ? "headerFixed" : "" }>
        <div id="title">
            <div id="titleTitle">
                <Link to="/">
                    <img alt="Logo de l'entreprise, SOCO BOMA" src="/img/header/Logo_Big.png"/>
                </Link>
                <Link to="/">
                    <img alt="Logo de l'entreprise, SOCO BOMA" id="headerLogoLong" src="/img/header/Logo_Long.jpg"/>
                </Link>
                <Link to="/">
                    <img alt="Date de la creation de l'entreprise (1979)" src="/img/header/Logo_date.png"/>
                </Link>
            </div>
            <div className="titleMenu" onClick={ () => changeSmallNavBarVisible() }>
                <button className={ className } type="button"> 
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
            <div id="headerContact">
                <Link style={{ textDecoration: "none" }} to="/contact">
                    <img alt="Drapeau de la France" src="/img/header/drapeau_France.png"/>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/contact">
                    <p>CONTACT</p>
                </Link>
            </div>
            <div>
                <div id="headerGoToTop" onClick={ () => scrollToTop() }>
                    <div>
                        <img alt="Fleche pour remonter au debut de la page web" src="/img/header/go_top.png"/>
                    </div>
                </div>
            </div>
            <HeaderBigNavBar selectedTab={ (props.selectedTab === undefined) ? -1 : props.selectedTab } selected={ bigNavBarSelected } changeSelected={ (value) => changeNabBarSelected(value) } menu={ menu }/>
        </div>
        </header>
        {
            (smallNavBarVisible) ? <HeaderSmallNavBar menu={ menu }/> : false
        }
    </div>);
}

export default Header;