import { Page, Image, Text, View, Document, StyleSheet  } from '@react-pdf/renderer';

const MyDoc = ({ promo }) => {
    const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    let dateStart = "";
    let dateEnd = "";
    let dateStartObj = new Date(promo.dateStart);
    let dateEndObj = new Date(promo.dateEnd);

    dateStart += dateStartObj.getDate() + " " + monthNames[dateStartObj.getMonth()];
    dateEnd += dateEndObj.getDate() + " " + monthNames[dateEndObj.getMonth()] + " " + dateEndObj.getFullYear();

    let type;

    switch (promo.unite) {
        case "KILO":
            type = "kg";
            break;
        case "PIECE":
            type = "Piéce";
            break;
        case "BARQUETTE":
            type = "Barquette";
            break;
        case "CARTON":
            type = "Carton";
            break;
        case "BOITE":
            type = "Boîte";
            break;
        case "SAC":
            type = "Sac";
            break;
        default:
            type = "ERROR";
    }

    return (<Document>
            <Page size="A4" style={{ padding: "20px 40px" }}>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Image style={{ width: 200 }} src="/img/header/Logo_Big.png"/>
                    <Image style={{ width: 80 }} src="/img/header/Logo_date.png"/>
                </View>
                <View style={{ textAlign: "center", padding: "20px 0px" }}>
                    <Text style={{  fontSize: 28, color: "#145588" }}>{ promo.name }</Text>
                    <Text style={{  fontSize: 16, textTransform: "capitalize" }}>DU { dateStart.toUpperCase() } AU { dateEnd.toUpperCase() }</Text>
                </View>
                <View style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <Image  style={{ maxWidth: 300, maxHeight: 300 }} src={ process.env.REACT_APP_API_URL + "/img/" + promo.img }/>
                </View>
                <View style={{ textAlign: "center", padding: "20px 0px", marginBottom: 20 }}>
                    <Text style={{ fontSize: 12 }}>{ promo.description }</Text>
                </View>
                {
                    promo.prices.map((val, i) => { 
                        return (<View key={i} style={{ flexDirection: "row", justifyContent: "center" }}>
                            <Text style={{ width: 200, textAlign: "right", margin: "0 20px" }}>{ val.name }</Text>
                            <Text style={{ width: 200, margin: "0 20px" }}>{ val.price } € / { type }</Text>
                        </View>);
                    })
                }
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 20, bottom: 20, position: 'absolute', left: 40, right: 40 }} fixed>
                    <View>
                        <Text style={{ fontSize: 10 }}>SOCOBOMA - SA à capital variable - La Raoulerie – 50008 Saint-Lô Cedex – Tél. 02 33 57 74 25 - SIRET 31547592100013 – RCS Coutances – code NAF 4617A - N° Agrément communautaire FR 50.502.006 CE</Text>
                    </View>
                </View>
            </Page>
        </Document>)
};

export default MyDoc;