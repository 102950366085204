function PromotionPDF ({ promo }) {
    console.log(promo);
    return (
    <div id="promotionInside">
        <div style={{ overflow: "hidden" }}>
            <iframe style={{ width: "100%", height: "100%" }} src={ process.env.REACT_APP_API_URL + "/img/" + promo.pdf }/>
        </div>
    </div>)
}

export default PromotionPDF;