import { Link } from "react-router-dom";

function CVG () {
    return (<div style={{ fontFamily: 'roboto', maxWidth: "calc(100% - 40px)", width: 1000, padding: 20, margin: "auto" }}>
        <h2>CONDITIONS GÉNÉRALES DE VENTE – SOCOBOMA</h2>
        Applicables à compter du 1er janvier 2013
        
        <ol>
            <li style={{ margin: "10px 0" }}>DOMAINE D’APPLICATION – Les présentes conditions générales s’appliquent pour toutes les ventes de la société SOCOBOMA. Aucune condition particulière ne peut, sauf accord préalable écrit de la société SOCOBOMA, prévaloir contre les Conditions Générales de Ventes (CGV). Toute commande implique la connaissance des présentes CGV et leur acceptation sans réserve.</li>
            <li style={{ margin: "10px 0" }}>TARIFS – Le prix des facturations appliqué est celui du tarif convenu entre les parties. Les tarifs s’entendent hors taxes et sont valables selon la fiscalité en vigueur, toute évolution de cette dernière entraînera une revalorisation intégrale et immédiate des tarifs.</li>
            <li style={{ margin: "10px 0" }}>LIVRAISONS – Les délais de livraison ne sont donnés qu’à titre indicatif, les retards éventuels ne pourront donner lieu à aucune indemnité, ni retenue, ni entrainer l’annulation de la commande. Notre société pourra facturer si nécessaire des frais de livraison.</li>
            <li style={{ margin: "10px 0" }}>RÉSERVE DE PROPRIÉTÉ – Par application de la Loi 80.335 du 12 mai 1980, le transfert de propriété des marchandises livrées à l’acheteur est subordonné à leur paiement intégral. Le paiement ne pourra être considéré effectué que lors de l’encaissement effectif du prix par le vendeur.</li>
            <li style={{ margin: "10px 0" }}>CONDITION DE PAIEMENT – Le montant intégral de la facture doit être réglé au plus tard à l’échéance convenue, toute compensation ou déduction à quelque titre que ce soit étant interdite.<br/>
            La société SOCOBOMA n’accorde ni ristourne, ni rabais, ni escompte au titre d’un paiement anticipé.<br/>
            Nos conditions de paiement sont toujours susceptibles de modification en cas de détérioration de la situation financière de l’acheteur. Notre société se réserve le droit d’exiger le paiement comptant au moment de la livraison.</li>
            <li style={{ margin: "10px 0" }}>PÉNALITÉS DE RETARD – Conformément aux dispositions des articles L.441-6 du Code de commerce et 98 du Code des Marchés Publics, toute inexécution par l’acheteur, qu’elle soit totale ou partielle, de ses obligations de paiement ou tout retard, entraîne de plein droit et sans qu’aucune mise en demeure ne soit nécessaire le paiement d’une pénalité de retard d’un montant égal au taux d’intérêt légal, dans la limite de 1,5%. Cette pénalité commence à courir à compter du jour suivant la date de règlement figurant sur la facture.</li>
            <li style={{ margin: "10px 0" }}>FRAIS DE RECOUVREMENT – Conformément à l’article L.441-6 du Code de commerce, tout retard de paiement donnera lieu de plein droit au paiement par l’acheteur d’une indemnité forfaitaire d’un montant de QUARANTE EUROS (40€) au titre des frais de recouvrement.</li>
            <li style={{ margin: "10px 0" }}>CLAUSE PÉNALE – En cas de retard dans le paiement du prix, la société SOCOBOMA sera en droit de demander à l’acheteur le paiement d’une somme égale à TROIS POURCENTS (3%) du prix de vente par mois de retard, à titre de dommages-intérêt. Les dommages-intérêts dus à ce titre sont fixés à titre forfaitaire et irrévocable.</li>
            <li style={{ margin: "10px 0" }}>LOI APPLICABLE ET ATTRIBUTION DE COMPÉTENCES – Toutes les ventes conclues par la société SOCOBOMA sont soumises à la loi française et seules les règles de procédures en vigueur en France sont applicables. Tout litige qui n’aurait pu être réglé à l’amiable sera de la compétence exclusive du Tribunal de Commerce de Coutances, même en cas d’appel en garantie ou de pluralité de défendeurs et nonobstant toute clause attributive contraire.</li>
        </ol>
        <div style={{ textAlign: "center" }}>
            <Link style={{ textDecoration: "none", width: "100%" }} to="/">
                <img src="/img/commun/cross.png" style={{ width: 40 }}/>
            </Link>
        </div>
    </div>)
}

export default CVG;